import React, { useState, useEffect } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import './App.css';
import bg from "./logo2.png";

const { REACT_APP_CFF_API_ENDPOINT, REACT_APP_CFF_FORM_ID, REACT_APP_CFF_API_KEY } = process.env;


function App() {
  const [values, setValues] = useState(null);
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let { res } = await fetch(`${REACT_APP_CFF_API_ENDPOINT}/forms/${REACT_APP_CFF_FORM_ID}/responses?apiKey=${REACT_APP_CFF_API_KEY}`, { headers: { Authorization: "anon" } }).then(e => e.json());
        let values = [];
        for (let response of res) {
          if (response.date_created && response.value.participants && response.value.address && response.value.participants.length && response.date_created.$date) {
            for (let participant of response.value.participants) {
              let number = Number(participant.number);
              if (number) {
                values.push({
                  time: response.date_created.$date,
                  name: participant.name,
                  number,
                  city: response.value.address.city,
                  state: response.value.address.state,
                  country: response.value.address.country,
                });
              }
            }
          }
        }
        // sort descending
        setValues([...values].sort((a, b) => b.time - a.time));
        let runningTotal = 0;
        let chartData = values.map(e => ({
          ...e,
          number: runningTotal += e.number
        }))
        setChartData(chartData);
        setTimeout(fetchData, 30000); // 30 seconds
      } catch (e) {
        console.error(e);
        setTimeout(fetchData, 200000); // 200 seconds
      }
    }
    fetchData();
  }, []);
  if (!values || !chartData) {
    return <div class="loader"></div>;
  }
  return (
    <div className="App">
      <img alt="Sanjeevan Hanuman" src={bg} className="background" /><br />
      <a href="http://www.chinmayamission.com/sanjeevan-hanuman-followers/" target="_blank">Add your name here</a><br /><br />
      <a target="_blank" href="https://www.youtube.com/watch?v=Z1j3LaOFHRk">Watch the livestream</a>
      {/* <h1>Sanjeevan Hanuman</h1>
      <h2>For strength we play, for unity we relay</h2> */}
      <h2>Total counts</h2>
      <ResponsiveContainer width={"100%"} height={250}>
      <LineChart data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <Line type="monotone" dataKey="number" stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="time" tickFormatter={e => {
          const date = new Date(e);
          return `${date.getHours()}:${date.getMinutes() < 10 ? "0": ""}${date.getMinutes()}`;
        }} />
        <YAxis />
        {/* <Tooltip /> */}
      </LineChart>
      </ResponsiveContainer>
      <div>
        <h2>Chanting along...</h2>
        <table>
          {/* sort descending */}
          <thead>
            <tr><th>Name</th><th>City</th><th>State</th><th>Country</th><th>Count</th></tr>
          </thead>
          <tbody>
          {values.map(({ name, number, city, state, country }, i) => i < 2000 && (
            <tr key={i}><td>{name}</td><td>{city}</td><td>{state}</td><td>{country}</td><td>{number} time{number > 1 ? "s": ""}</td></tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;
